import {
  CheckOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import { MediaDevice } from "../video-types";
import { getAntdItem, MenuItem as MenuItemType } from "./video-footer-utils";
import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  VStack,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";
import { messages } from "../../../lang/messages";
interface CameraButtonProps {
  isStartedVideo: boolean;
  isMirrored?: boolean;
  isBlur?: boolean;
  isPreview?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  onMirrorVideo?: () => void;
  onVideoStatistic?: () => void;
  onBlurBackground?: () => void;
  onSelectVideoPlayback?: (url: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
  activePlaybackUrl?: string;
}
const CameraButton = (props: CameraButtonProps) => {
  const { formatMessage } = useIntl();
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onVideoStatistic,
    onBlurBackground,
    onSelectVideoPlayback,
  } = props;
  const onMenuItemClick = (payload: { key: any }) => {
    if (payload.key === "mirror") {
      onMirrorVideo?.();
    } else if (payload.key === "statistic") {
      onVideoStatistic?.();
    } else if (payload.key === "blur") {
      onBlurBackground?.();
    } else if (/^https:\/\//.test(payload.key)) {
      onSelectVideoPlayback?.(payload.key);
    } else {
      onSwitchCamera(payload.key);
    }
  };
  const menuItems =
    cameraList &&
    cameraList.length > 0 &&
    ([
      getAntdItem(
        formatMessage(messages.selectCamera),
        "camera",
        undefined,
        cameraList.map(item =>
          getAntdItem(
            item.label,
            item.deviceId,
            item.deviceId === activeCamera && <CheckOutlined />,
          ),
        ),
        "group",
      ),
    ].filter(Boolean) as MenuItemType[]);
  return (
    <div className={classNames("camera-footer", className)}>
      {isStartedVideo && menuItems ? (
        <HStack spacing="0px" gap="0px">
          <IconButton
            aria-label=""
            icon={<VideoCameraOutlined />}
            onClick={onCameraClick}
            borderRightRadius="0px"
            colorScheme="green"
          />
          <Menu placement="auto">
            <MenuButton as={Button} borderLeftRadius="0px" colorScheme="green">
              <ChevronUpIcon />
            </MenuButton>
            <MenuList>
              {menuItems.map((item: MenuItemType, index) => {
                return (
                  <VStack key={index}>
                    {(
                      item as {
                        label: React.ReactNode;
                        key: React.Key;
                        icon?: React.ReactNode;
                        children?: MenuItemType[];
                        type?: string;
                      }
                    )?.children ? (
                      <MenuOptionGroup
                        type="radio"
                        defaultValue="first"
                        title={(
                          item as {
                            label: React.ReactNode;
                            key: React.Key;
                            icon?: React.ReactNode;
                            children?: MenuItemType[];
                            type?: string;
                          }
                        )?.label?.toString()}>
                        {(
                          item as {
                            label: React.ReactNode;
                            key: React.Key;
                            icon?: React.ReactNode;
                            children?: MenuItemType[];
                            type?: string;
                          }
                        )?.children?.map((child, index) => {
                          return (
                            <MenuItemOption
                              key={index}
                              value={
                                index === 0 ? "first" : child?.key?.toString()
                              }
                              onClick={() =>
                                onMenuItemClick(
                                  child as {
                                    label: React.ReactNode;
                                    key: React.Key;
                                    icon?: React.ReactNode;
                                    children?: MenuItemType[];
                                    type?: string;
                                  },
                                )
                              }>
                              {
                                (
                                  child as {
                                    label: React.ReactNode;
                                    key: React.Key;
                                    icon?: React.ReactNode;
                                    children?: MenuItemType[];
                                    type?: string;
                                  }
                                )?.label
                              }
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    ) : (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          onMenuItemClick(
                            item as {
                              label: React.ReactNode;
                              key: React.Key;
                              icon?: React.ReactNode;
                              children?: MenuItemType[];
                              type?: string;
                            },
                          )
                        }>
                        {
                          (
                            item as {
                              label: React.ReactNode;
                              key: React.Key;
                              icon?: React.ReactNode;
                              children?: MenuItemType[];
                              type?: string;
                            }
                          )?.label
                        }
                      </MenuItem>
                    )}
                  </VStack>
                );
              })}
            </MenuList>
          </Menu>
        </HStack>
      ) : (
        <Button colorScheme="red" onClick={onCameraClick}>
          {isStartedVideo ? (
            <VideoCameraOutlined />
          ) : (
            <VideoCameraAddOutlined />
          )}
        </Button>
      )}
    </div>
  );
};
export default CameraButton;
