import { Spinner } from "@chakra-ui/react";
import "./loading-layer.scss";

const LoadingLayer = (props: { content: any }) => {
  return (
    <div className="loading-layer">
      <Spinner color="red.500" size="xl" boxSize="100px" thickness="5px" />
    </div>
  );
};

export default LoadingLayer;
