/* eslint-disable no-console */
import {
  useEffect,
  useContext,
  useState,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import ZoomVideo, { ConnectionState } from "@zoom/videosdk";
import { Modal } from "antd";
import "antd/dist/antd.min.css";
import produce from "immer";
import Home from "./feature/home/home";
import ZoomContext from "./context/zoom-context";
import ZoomMediaContext from "./context/media-context";
import { MediaStream } from "./index-types";
import "./App.css";

import { ChakraProvider } from "@chakra-ui/react";
import I18nProvider from "./component/I18nProvider";
interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    customerJoinId?: string;
    lang?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload;
      break;
    }
    case "audio-decode": {
      draft.audio.decode = action.payload;
      break;
    }
    case "video-encode": {
      draft.video.encode = action.payload;
      break;
    }
    case "video-decode": {
      draft.video.decode = action.payload;
      break;
    }
    case "share-encode": {
      draft.share.encode = action.payload;
      break;
    }
    case "share-decode": {
      draft.share.decode = action.payload;
      break;
    }
    case "reset-media": {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}

function App(props: AppProps) {
  const {
    meetingArgs: {
      sdkKey,
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      customerJoinId,
    },
  } = props;
  const [loading, setIsLoading] = useState(false);
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("closed");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] =
    useState<boolean>(true);
  const zmClient = useContext(ZoomContext);
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? "zoom.us";
  }
  const mediaContext = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream],
  );
  const galleryViewWithoutSAB =
    Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  useEffect(() => {
    const init = async () => {
      await zmClient.init("en-US", `${window.location.origin}/lib`, {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: galleryViewWithoutSAB,
        stayAwake: false,
        leaveOnPageUnload: true,
      });
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    sdkKey,
    signature,
    zmClient,
    topic,
    name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
  ]);
  const onConnectionChange = useCallback(
    (payload: { state?: any; reason: any; subsessionName?: any }) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus("connecting");
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected");
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed");
        dispatch({ type: "reset-media" });
        if (payload.reason === "ended by host") {
          Modal.warning({
            title: "Meeting ended",
            content: "This meeting has been ended by host",
          });
        }
      }
    },
    [isFailover, zmClient],
  );
  const onMediaSDKChange = useCallback(
    (payload: { action: any; type: any; result: any }) => {
      const { action, type, result } = payload;
      dispatch({ type: `${type}-${action}`, payload: result === "success" });
    },
    [],
  );

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === "closed") {
      setIsLoading(true);
      try {
        await zmClient.join(topic, signature, name, password);
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
      setIsLoading(false);
    } else if (status === "connected") {
      await zmClient.leave();
    }
  }, [zmClient, status, topic, signature, name, password]);

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange);
    zmClient.on("media-sdk-change", onMediaSDKChange);
    return () => {
      zmClient.off("connection-change", onConnectionChange);
      zmClient.off("media-sdk-change", onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return (
    <ChakraProvider>
      <I18nProvider>
        <div className="App">
          <ZoomMediaContext.Provider value={mediaContext}>
            <Home
              {...props}
              status={status}
              onLeaveOrJoinSession={onLeaveOrJoinSession}
              isSupportGalleryView={isSupportGalleryView}
              galleryViewWithoutSAB={galleryViewWithoutSAB}
              isLoading={loading}
            />
          </ZoomMediaContext.Provider>
        </div>
      </I18nProvider>
    </ChakraProvider>
  );
}

export default App;
