/* eslint-disable no-console */
import {
  useState,
  useCallback,
  useContext,
  useEffect,
  SetStateAction,
} from "react";
import classNames from "classnames";
import ZoomContext from "../../../context/zoom-context";
import CameraButton from "./camera";
import MicrophoneButton from "./microphone";
import { ScreenShareButton } from "./screen-share";
import AudioVideoStatisticModal from "./audio-video-statistic";
import ZoomMediaContext from "../../../context/media-context";
import { useUnmount, useMount } from "../../../hooks";
import { MediaDevice } from "../video-types";
import "./video-footer.scss";
import { isAndroidOrIOSBrowser, isIOSMobile } from "../../../utils/platform";
import {
  getPhoneCallStatusDescription,
  SELF_VIDEO_ID,
} from "../video-constants";
import {
  DialoutState,
  RecordingStatus,
  AudioChangeAction,
  DialOutOption,
  VideoCapturingState,
  SharePrivilege,
  MobileVideoFacingMode,
  LiveStreamStatus,
  ShareStatus,
} from "@zoom/videosdk";
import { LeaveButton } from "./leave";
import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { messages } from "../../../lang/messages";
interface VideoFooterProps {
  className?: string;
  selfShareCanvas?: HTMLCanvasElement | HTMLVideoElement | null;
  sharing?: boolean;
}

const isAudioEnable = typeof AudioWorklet === "function";
const VideoFooter = ({
  ...props
}: VideoFooterProps & {
  isStartedAudio: boolean;
  isMuted: boolean;
  isStartedVideo: boolean;
  setIsStartedAudio: (bool: boolean) => void;
  setIsMuted: (bool: boolean) => void;
  setIsStartedVideo: (bool: boolean) => void;
}) => {
  const { formatMessage } = useIntl();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { className, selfShareCanvas, sharing } = props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const liveStreamClient = zmClient.getLiveStreamClient();
  const recordingClient = zmClient.getRecordingClient();
  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== "",
  );
  const [isStartedVideo, setIsStartedVideo] = useState(
    zmClient.getCurrentUserInfo()?.bVideoOn,
  );
  const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [, setIsStartedLiveTranscription] = useState(false);
  const [, setIsDisableCaptions] = useState(false);
  const [isMuted, setIsMuted] = useState(
    !!zmClient.getCurrentUserInfo()?.muted,
  );
  const [activeMicrophone, setActiveMicrophone] = useState(
    mediaStream?.getActiveMicrophone(),
  );
  const [activeSpeaker, setActiveSpeaker] = useState(
    mediaStream?.getActiveSpeaker(),
  );
  const [activeCamera, setActiveCamera] = useState(
    mediaStream?.getActiveCamera(),
  );
  const [micList, setMicList] = useState<MediaDevice[]>(
    mediaStream?.getMicList() ?? [],
  );
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(
    mediaStream?.getSpeakerList() ?? [],
  );
  const [cameraList, setCameraList] = useState<MediaDevice[]>(
    mediaStream?.getCameraList() ?? [],
  );
  const [statisticVisible, setStatisticVisible] = useState(false);
  const [selecetedStatisticTab, setSelectedStatisticTab] = useState("audio");
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
  const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
  const [, setCaption] = useState({ text: "", isOver: false });
  const [activePlaybackUrl, setActivePlaybackUrl] = useState("");
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
  const [, setRecordingStatus] = useState<"" | RecordingStatus>(
    recordingClient?.getCloudRecordingStatus() || "",
  );
  const [, setRecordingIsoStatus] = useState<"" | RecordingStatus>("");
  const [, setLiveStreamStatus] = useState(
    liveStreamClient?.getLiveStreamStatus(),
  );

  useEffect(() => {
    const setAsPreview = async () => {
      if (props.isStartedVideo) {
        console.log("Cross Origin Isolated: " + window.crossOriginIsolated);

        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as HTMLVideoElement;

        if (mediaStream?.isRenderSelfViewWithVideoElement() && videoElement) {
          if (videoElement) {
            await mediaStream?.startVideo({ videoElement });
          }
        } else {
          const startVideoOptions = {
            hd: false,
            ptz: mediaStream?.isBrowserSupportPTZ(),
          };
          await mediaStream?.startVideo(startVideoOptions);
          if (!mediaStream?.isSupportMultipleVideos()) {
            const canvasElement = document.querySelector(
              `#${SELF_VIDEO_ID}`,
            ) as HTMLCanvasElement;

            mediaStream?.renderVideo(
              canvasElement,
              zmClient.getSessionInfo().userId,
              canvasElement.width,
              canvasElement.height,
              0,
              0,
              3,
            );
          }
        }

        setIsStartedVideo(true);
      }

      if (props.isStartedAudio) {
        try {
          await mediaStream?.startAudio();
        } catch (e: any) {
          if (
            e.type === "INSUFFICIENT_PRIVILEGES" &&
            e.reason === "USER_FORBIDDEN_MICROPHONE"
          ) {
            setIsMicrophoneForbidden(true);
          }
          console.warn(e);
        }
        setIsStartedAudio(true);

        if (props.isMuted) {
          await mediaStream?.muteAudio();
        }
      }
    };

    if (!isIOSMobile()) setAsPreview();
  }, [
    mediaStream,
    props.isMuted,
    props.isStartedAudio,
    props.isStartedVideo,
    zmClient,
  ]);

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
    } else {
      console.log("Cross Origin Isolated: " + window.crossOriginIsolated);

      const videoElement = document.querySelector(
        `#${SELF_VIDEO_ID}`,
      ) as HTMLVideoElement;
      if (mediaStream?.isRenderSelfViewWithVideoElement() && videoElement) {
        if (videoElement) {
          await mediaStream?.startVideo({ videoElement });
        }
      } else {
        const startVideoOptions = {
          hd: false,
          ptz: mediaStream?.isBrowserSupportPTZ(),
        };
        await mediaStream?.startVideo(startVideoOptions);
        if (!mediaStream?.isSupportMultipleVideos()) {
          const canvasElement = document.querySelector(
            `#${SELF_VIDEO_ID}`,
          ) as HTMLCanvasElement;
          mediaStream?.renderVideo(
            canvasElement,
            zmClient.getSessionInfo().userId,
            canvasElement.width,
            canvasElement.height,
            0,
            0,
            3,
          );
        }
      }

      setIsStartedVideo(true);
    }
  }, [mediaStream, isStartedVideo, zmClient]);
  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
      } else {
        await mediaStream?.muteAudio();
      }
    } else {
      try {
        await mediaStream?.startAudio();
      } catch (e: any) {
        if (
          e.type === "INSUFFICIENT_PRIVILEGES" &&
          e.reason === "USER_FORBIDDEN_MICROPHONE"
        ) {
          setIsMicrophoneForbidden(true);
        }
        console.warn(e);
      }
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);
  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split("|");
      if (type === "microphone") {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === "speaker") {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === "leave audio") {
        if (audio === "computer") {
          await mediaStream.stopAudio();
        } else if (audio === "phone") {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
        setIsStartedAudio(false);
      } else if (type === "statistic") {
        setSelectedStatisticTab("audio");
        setStatisticVisible(true);
      }
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
        setActivePlaybackUrl("");
      }
    }
  };
  const onPhoneCall = async (
    code: string,
    phoneNumber: string,
    name: string,
    option: DialOutOption,
  ) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  };
  const onPhoneCallCancel = async (
    code: string,
    phoneNumber: string,
    option: { callMe: boolean },
  ) => {
    if (
      [
        DialoutState.Calling,
        DialoutState.Ringing,
        DialoutState.Accepted,
      ].includes(phoneCallStatus as any)
    ) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });
    }
    return Promise.resolve();
  };
  const onHostAudioMuted = useCallback(
    (payload: { action: any; source: any; type: any }) => {
      const { action, type } = payload;
      if (action === AudioChangeAction.Join) {
        setIsStartedAudio(true);
        setAudio(type);
      } else if (action === AudioChangeAction.Leave) {
        setIsStartedAudio(false);
      } else if (action === AudioChangeAction.Muted) {
        setIsMuted(true);
      } else if (action === AudioChangeAction.Unmuted) {
        setIsMuted(false);
      }
    },
    [],
  );
  const onScreenShareClick = useCallback(async () => {
    if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
      await mediaStream?.startShareScreen(selfShareCanvas, {
        requestReadReceipt: true,
      });
    }
  }, [mediaStream, selfShareCanvas]);

  const onLeaveClick = useCallback(async () => {
    await zmClient.leave();
    location.reload();
  }, [zmClient]);

  const onEndClick = useCallback(async () => {
    await zmClient.leave(true);
    location.reload();
  }, [zmClient]);

  const onPassivelyStopShare = useCallback(({ reason }: { reason: string }) => {
    console.log("passively stop reason:", reason);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onRecordingChange = useCallback(() => {
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || "");
  }, [recordingClient]);

  const onRecordingISOChange = useCallback(
    (payload: any) => {
      if (
        payload?.userId === zmClient.getSessionInfo().userId ||
        payload?.status === RecordingStatus.Ask
      ) {
        setRecordingIsoStatus(payload?.status);
      }
      console.log("recording-iso-change", payload);
    },
    [zmClient],
  );

  const onDialOutChange = useCallback(
    (payload: { code: SetStateAction<DialoutState | undefined> }) => {
      setPhoneCallStatus(payload.code);
    },
    [],
  );

  const onVideoCaptureChange = useCallback(
    (payload: { state: VideoCapturingState }) => {
      if (payload.state === VideoCapturingState.Started) {
        setIsStartedVideo(true);
      } else {
        setIsStartedVideo(false);
      }
    },
    [],
  );
  const onShareAudioChange = useCallback(
    (payload: { state: any }) => {
      const { state } = payload;
      if (state === "on") {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === "off") {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream],
  );

  const onCaptionMessage = useCallback((payload: { text: any; done: any }) => {
    const { text, done } = payload;
    setCaption({
      text,
      isOver: done,
    });
  }, []);

  const onCaptionDisable = useCallback(
    (payload: boolean | ((prevState: boolean) => boolean)) => {
      setIsDisableCaptions(payload);
      if (payload) {
        setIsStartedLiveTranscription(false);
      }
    },
    [],
  );

  const onSelectVideoPlayback = useCallback(
    async (url: string) => {
      if (activePlaybackUrl !== url) {
        await mediaStream?.switchCamera({ url, loop: true });
        if (isStartedAudio) {
          await mediaStream?.switchMicrophone({ url, loop: true });
        } else {
          await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
        }
        setActivePlaybackUrl(url);
      }
    },
    [isStartedAudio, activePlaybackUrl, mediaStream],
  );
  const onLiveStreamStatusChange = useCallback((status: LiveStreamStatus) => {
    setLiveStreamStatus(status);
  }, []);
  useEffect(() => {
    zmClient.on("current-audio-change", onHostAudioMuted);
    zmClient.on("passively-stop-share", onPassivelyStopShare);
    zmClient.on("device-change", onDeviceChange);
    zmClient.on("recording-change", onRecordingChange);
    zmClient.on("individual-recording-change", onRecordingISOChange);
    zmClient.on("dialout-state-change", onDialOutChange);
    zmClient.on("video-capturing-change", onVideoCaptureChange);
    zmClient.on("share-audio-change", onShareAudioChange);
    zmClient.on("caption-message", onCaptionMessage);
    zmClient.on("caption-host-disable", onCaptionDisable);
    zmClient.on("live-stream-status", onLiveStreamStatusChange);
    return () => {
      zmClient.off("current-audio-change", onHostAudioMuted);
      zmClient.off("passively-stop-share", onPassivelyStopShare);
      zmClient.off("device-change", onDeviceChange);
      zmClient.off("recording-change", onRecordingChange);
      zmClient.off("individual-recording-change", onRecordingISOChange);
      zmClient.off("dialout-state-change", onDialOutChange);
      zmClient.off("video-capturing-change", onVideoCaptureChange);
      zmClient.off("share-audio-change", onShareAudioChange);
      zmClient.off("caption-message", onCaptionMessage);
      zmClient.off("caption-host-disable", onCaptionDisable);
      zmClient.off("live-stream-status", onLiveStreamStatusChange);
    };
  }, [
    zmClient,
    onHostAudioMuted,
    onPassivelyStopShare,
    onDeviceChange,
    onRecordingChange,
    onDialOutChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onCaptionMessage,
    onRecordingISOChange,
    onCaptionDisable,
    onLiveStreamStatusChange,
  ]);
  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
    mediaStream?.stopShareScreen();
  });
  useMount(() => {
    if (mediaStream) {
      setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
      setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
      setSharePrivileg(mediaStream.getSharePrivilege());
      if (isAndroidOrIOSBrowser()) {
        setCameraList([
          { deviceId: MobileVideoFacingMode.User, label: "Front-facing" },
          { deviceId: MobileVideoFacingMode.Environment, label: "Rear-facing" },
        ]);
      }
    }
  });
  useEffect(() => {
    if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
      mediaStream.subscribeAudioStatisticData();
      mediaStream.subscribeVideoStatisticData();
      mediaStream.subscribeShareStatisticData();
    }
    return () => {
      if (zmClient.getSessionInfo().isInMeeting) {
        mediaStream?.unsubscribeAudioStatisticData();
        mediaStream?.unsubscribeVideoStatisticData();
        mediaStream?.unsubscribeShareStatisticData();
      }
    };
  }, [mediaStream, zmClient]);
  return (
    <div className={classNames("video-footer", className)}>
      {isIOSMobile() ? (
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          placement="top"
          closeOnBlur={false}
          returnFocusOnClose={false}
          autoFocus={false}
          colorScheme="red">
          <PopoverTrigger>
            <HStack
              bgColor="gray.100"
              p="10px"
              borderRadius="lg"
              boxShadow="lg">
              {isAudioEnable && (
                <MicrophoneButton
                  isStartedAudio={isStartedAudio}
                  isMuted={isMuted}
                  isSupportPhone={isSupportPhone}
                  audio={audio}
                  phoneCountryList={phoneCountryList}
                  onPhoneCallClick={onPhoneCall}
                  onPhoneCallCancel={onPhoneCallCancel}
                  phoneCallStatus={getPhoneCallStatusDescription(
                    phoneCallStatus,
                  )}
                  onMicrophoneClick={onMicrophoneClick}
                  onMicrophoneMenuClick={onMicrophoneMenuClick}
                  microphoneList={micList}
                  speakerList={speakerList}
                  activeMicrophone={activeMicrophone}
                  activeSpeaker={activeSpeaker}
                  disabled={isComputerAudioDisabled}
                  isMicrophoneForbidden={isMicrophoneForbidden}
                />
              )}
              <CameraButton
                isStartedVideo={isStartedVideo}
                onCameraClick={onCameraClick}
                onSwitchCamera={onSwitchCamera}
                onVideoStatistic={() => {
                  setSelectedStatisticTab("video");
                  setStatisticVisible(true);
                }}
                onSelectVideoPlayback={onSelectVideoPlayback}
                activePlaybackUrl={activePlaybackUrl}
                cameraList={cameraList}
                activeCamera={activeCamera}
              />
              {sharing && (
                <ScreenShareButton
                  sharePrivilege={sharePrivilege}
                  isHostOrManager={zmClient.isHost() || zmClient.isManager()}
                  onScreenShareClick={onScreenShareClick}
                  onSharePrivilegeClick={async privilege => {
                    await mediaStream?.setSharePrivilege(privilege);
                    setSharePrivileg(privilege);
                  }}
                />
              )}
              <LeaveButton
                onLeaveClick={onLeaveClick}
                isHost={zmClient.isHost()}
                onEndClick={onEndClick}
              />
              <AudioVideoStatisticModal
                visible={statisticVisible}
                setVisible={setStatisticVisible}
                defaultTab={selecetedStatisticTab}
                isStartedAudio={isStartedAudio}
                isMuted={isMuted}
                isStartedVideo={isStartedVideo}
              />
            </HStack>
          </PopoverTrigger>
          <PopoverContent color="white" bg="red.500" borderColor="red.500">
            <PopoverArrow bg="red.500" />
            <PopoverCloseButton />
            <PopoverBody>{formatMessage(messages.remember)}</PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <HStack bgColor="gray.100" p="10px" borderRadius="lg" boxShadow="lg">
          {isAudioEnable && (
            <MicrophoneButton
              isStartedAudio={isStartedAudio}
              isMuted={isMuted}
              isSupportPhone={isSupportPhone}
              audio={audio}
              phoneCountryList={phoneCountryList}
              onPhoneCallClick={onPhoneCall}
              onPhoneCallCancel={onPhoneCallCancel}
              phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
              onMicrophoneClick={onMicrophoneClick}
              onMicrophoneMenuClick={onMicrophoneMenuClick}
              microphoneList={micList}
              speakerList={speakerList}
              activeMicrophone={activeMicrophone}
              activeSpeaker={activeSpeaker}
              disabled={isComputerAudioDisabled}
              isMicrophoneForbidden={isMicrophoneForbidden}
            />
          )}
          <CameraButton
            isStartedVideo={isStartedVideo}
            onCameraClick={onCameraClick}
            onSwitchCamera={onSwitchCamera}
            onVideoStatistic={() => {
              setSelectedStatisticTab("video");
              setStatisticVisible(true);
            }}
            onSelectVideoPlayback={onSelectVideoPlayback}
            activePlaybackUrl={activePlaybackUrl}
            cameraList={cameraList}
            activeCamera={activeCamera}
          />
          {sharing && (
            <ScreenShareButton
              sharePrivilege={sharePrivilege}
              isHostOrManager={zmClient.isHost() || zmClient.isManager()}
              onScreenShareClick={onScreenShareClick}
              onSharePrivilegeClick={async privilege => {
                await mediaStream?.setSharePrivilege(privilege);
                setSharePrivileg(privilege);
              }}
            />
          )}
          <LeaveButton
            onLeaveClick={onLeaveClick}
            isHost={zmClient.isHost()}
            onEndClick={onEndClick}
          />
          <AudioVideoStatisticModal
            visible={statisticVisible}
            setVisible={setStatisticVisible}
            defaultTab={selecetedStatisticTab}
            isStartedAudio={isStartedAudio}
            isMuted={isMuted}
            isStartedVideo={isStartedVideo}
          />
        </HStack>
      )}
    </div>
  );
};
export default VideoFooter;
