import { getExploreName } from "../utils/platform";

export const devConfig = {
  sdkKey: `${process.env.REACT_APP_ZOOM_SDK_KEY}`,
  sdkSecret: `${process.env.REACT_APP_ZOOM_SDK_SECRET}`,
  webEndpoint: "zoom.us",
  topic: "HEALED",
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: "healed",
  signature: "",
  token: "testappointment",
  userIdentity: "",
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1,
};
