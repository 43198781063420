import { IconFont } from "../../../component/icon-font";
import { Button } from "@chakra-ui/react";
interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick } = props;

  return (
    <Button colorScheme="red" onClick={onLeaveClick}>
      <IconFont type="icon-leave" />
    </Button>
  );
};

export { LeaveButton };
