import { ReactNode, useMemo } from "react";
import { IntlProvider } from "react-intl";

import messagesEn from "../lang/en.json";
import messagesPtBr from "../lang/pt-BR.json";

type Locale = string;

export const AVAILABLE_LANGUAGES = {
  EN: "en",
  PT_BR: "pt-BR",
};

const getMessages = (languageTag: Locale) => {
  switch (languageTag) {
    case AVAILABLE_LANGUAGES.PT_BR:
      return messagesPtBr;
    default:
      return messagesEn;
  }
};

const I18nProvider = ({ children }: Props) => {
  const defaultLocale = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const localeParam = params.get("locale");
    const isValidLocale = Object.values(AVAILABLE_LANGUAGES).includes(
      localeParam ?? "",
    );

    return isValidLocale ? (localeParam as string) : AVAILABLE_LANGUAGES.EN;
  }, []);

  return (
    <IntlProvider
      messages={getMessages(defaultLocale as Locale)}
      locale={defaultLocale}
      defaultLocale={defaultLocale}>
      {children}
    </IntlProvider>
  );
};

interface Props {
  children: ReactNode;
}

export default I18nProvider;
