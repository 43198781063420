import { IconFont } from "../../../component/icon-font";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { SharePrivilege } from "@zoom/videosdk";
import { Button, IconButton } from "@chakra-ui/react";

interface ScreenShareButtonProps {
  sharePrivilege: SharePrivilege;
  isHostOrManager: boolean;
  onSharePrivilegeClick?: (privilege: SharePrivilege) => void;
  onScreenShareClick: () => void;
}

interface ScreenShareLockButtonProps {
  isLockedScreenShare: boolean;
  onScreenShareLockClick: () => void;
}

const ScreenShareButton = (props: ScreenShareButtonProps) => {
  const { onScreenShareClick } = props;

  return (
    <Button colorScheme="red" onClick={onScreenShareClick}>
      <IconFont type="icon-share" />
    </Button>
  );
};

const ScreenShareLockButton = (props: ScreenShareLockButtonProps) => {
  const { isLockedScreenShare, onScreenShareLockClick } = props;
  return (
    <IconButton
      aria-label=""
      icon={isLockedScreenShare ? <LockOutlined /> : <UnlockOutlined />}
      onClick={onScreenShareLockClick}
      colorScheme="red"
    />
  );
};

export { ScreenShareButton, ScreenShareLockButton };
