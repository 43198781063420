import { defineMessages } from "react-intl";

export const messages = defineMessages({
  speaker: { id: "speaker" },
  microphone: { id: "microphone" },
  enterCall: { id: "enter-call" },
  testMic: { id: "test-mic" },
  recording: { id: "recording" },
  playing: { id: "playing" },
  stop: { id: "stop" },
  testSpeaker: { id: "test-speaker" },
  selectMic: { id: "select-mic" },
  selectSpeaker: { id: "select-speaker" },
  selectCamera: { id: "select-camera" },
  remember: { id: "remember" },
});
