import Video from "../video/video";
import VideoSingle from "../video/video-single";
import VideoNonSAB from "../video/video-non-sab";
import Preview from "../preview/preview";
import { Box, VStack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import LoadingLayer from "../../component/loading-layer";

interface HomeProps {
  status: string;
  onLeaveOrJoinSession: () => void;
  isSupportGalleryView?: boolean;
  galleryViewWithoutSAB?: boolean;
  isLoading: boolean;
}
const Home: React.FunctionComponent<HomeProps> = props => {
  const [isPreview, setIsPreview] = useState(true);
  const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);

  const setIsPreviewForJoin = useCallback(
    (isPreviewProp: boolean) => {
      if (!isPreviewProp) {
        props.onLeaveOrJoinSession();
        setIsPreview(false);
      }
    },
    [props],
  );

  if (props.isLoading) {
    return <LoadingLayer content="" />;
  }

  return isPreview ? (
    <VStack w="100%" p="10px" minH="100vh" align="center" justify="center">
      <Box
        bgColor="gray.100"
        position="fixed"
        padding="0"
        margin="0"
        top="0"
        left="0"
        w="500vw"
        h="500vh"
        zIndex="-1"
      />
      <VStack>
        <Preview
          setIsPreview={setIsPreviewForJoin}
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          isStartedVideo={isStartedVideo}
          setIsStartedAudio={setIsStartedAudio}
          setIsMuted={setIsMuted}
          setIsStartedVideo={setIsStartedVideo}
        />
      </VStack>
    </VStack>
  ) : props.isSupportGalleryView ? (
    <Video
      isStartedAudio={isStartedAudio}
      isMuted={isMuted}
      isStartedVideo={isStartedVideo}
      setIsStartedAudio={setIsStartedAudio}
      setIsMuted={setIsMuted}
      setIsStartedVideo={setIsStartedVideo}
    />
  ) : props.galleryViewWithoutSAB ? (
    <VideoNonSAB
      isStartedAudio={isStartedAudio}
      isMuted={isMuted}
      isStartedVideo={isStartedVideo}
      setIsStartedAudio={setIsStartedAudio}
      setIsMuted={setIsMuted}
      setIsStartedVideo={setIsStartedVideo}
    />
  ) : (
    <VideoSingle
      isStartedAudio={isStartedAudio}
      isMuted={isMuted}
      isStartedVideo={isStartedVideo}
      setIsStartedAudio={setIsStartedAudio}
      setIsMuted={setIsMuted}
      setIsStartedVideo={setIsStartedVideo}
    />
  );
};
export default Home;
