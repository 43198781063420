/* eslint-disable no-console */
import { useCallback, useState, useRef, useEffect } from "react";
import ZoomVideo, {
  TestMicrophoneReturn,
  TestSpeakerReturn,
} from "@zoom/videosdk";
import { useMount, useUnmount } from "../../hooks";
import "./preview.scss";
import MicrophoneButton from "../video/components/microphone";
import CameraButton from "../video/components/camera";
import { MediaDevice } from "../video/video-types";
import classNames from "classnames";
import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Select,
  Spacer,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { messages } from "../../lang/messages";
import { isIOSMobile } from "../../utils/platform";

// label: string;
// deviceId: string;
let prevMicFeedbackStyle = "";
// eslint-disable-next-line no-unused-vars
let micFeedBackInteval: any = "";

let localAudio = ZoomVideo.createLocalAudioTrack();
let localVideo = ZoomVideo.createLocalVideoTrack();
let allDevices;

const mountDevices: () => Promise<{
  mics: MediaDevice[];
  speakers: MediaDevice[];
  cameras: MediaDevice[];
}> = async () => {
  allDevices = await ZoomVideo.getDevices();
  const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter(device => {
    return device.kind === "videoinput";
  });
  const micDevices: Array<MediaDeviceInfo> = allDevices.filter(device => {
    return device.kind === "audioinput";
  });
  const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter(device => {
    return device.kind === "audiooutput";
  });
  return {
    mics: micDevices.map(item => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    speakers: speakerDevices.map(item => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    cameras: cameraDevices.map(item => {
      return { label: item.label, deviceId: item.deviceId };
    }),
  };
};

const updateMicFeedbackStyle = () => {
  const newVolumeIntensity = localAudio.getCurrentVolume();
  let newMicFeedbackStyle = "";

  if (newVolumeIntensity === 0) {
    newMicFeedbackStyle = "";
  } else if (newVolumeIntensity <= 0.05) {
    newMicFeedbackStyle = "mic-feedback__very-low";
  } else if (newVolumeIntensity <= 0.1) {
    newMicFeedbackStyle = "mic-feedback__low";
  } else if (newVolumeIntensity <= 0.15) {
    newMicFeedbackStyle = "mic-feedback__medium";
  } else if (newVolumeIntensity <= 0.2) {
    newMicFeedbackStyle = "mic-feedback__high";
  } else if (newVolumeIntensity <= 0.25) {
    newMicFeedbackStyle = "mic-feedback__very-high";
  } else {
    newMicFeedbackStyle = "mic-feedback__max";
  }
  const micIcon: any = document.getElementById("auido-volume-feedback");
  if (prevMicFeedbackStyle !== "" && micIcon) {
    micIcon.classList.toggle(prevMicFeedbackStyle);
  }

  if (newMicFeedbackStyle !== "" && micIcon) {
    micIcon.classList.toggle(newMicFeedbackStyle);
  }
  prevMicFeedbackStyle = newMicFeedbackStyle;
};

const PreviewContainer = ({
  setIsPreview,
  isStartedAudio,
  setIsStartedAudio,
  isMuted,
  setIsMuted,
  isStartedVideo,
  setIsStartedVideo,
  ...props
}: {
  setIsPreview: (bool: boolean) => void;
  isStartedAudio: boolean;
  isMuted: boolean;
  isStartedVideo: boolean;
  setIsStartedAudio: (bool: boolean) => void;
  setIsMuted: (bool: boolean) => void;
  setIsStartedVideo: (bool: boolean) => void;
}) => {
  const { formatMessage } = useIntl();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [activeCamera] = useState("");
  const [outputLevel, setOutputLevel] = useState(0);
  const [inputLevel, setInputLevel] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isPlayingRecording, setIsPlayingRecording] = useState(false);
  const [isInVBMode, setIsInVBMode] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const speakerTesterRef = useRef<TestSpeakerReturn>();
  const microphoneTesterRef = useRef<TestMicrophoneReturn>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const initializeVideoAudio = async () => {
      if (videoRef.current && !isStartedAudio) {
        await localVideo?.start(videoRef.current);
        setIsStartedVideo(true);
      }
      if (!isStartedAudio) {
        await localAudio?.start();
        setIsStartedAudio(true);

        if (isMuted) {
          await localAudio?.unmute();
          micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
          setIsMuted(false);
        }
      }
    };

    if (!isIOSMobile()) initializeVideoAudio();
  }, [
    isMuted,
    isStartedAudio,
    setIsMuted,
    setIsStartedAudio,
    setIsStartedVideo,
  ]);

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await localVideo?.stop();
      setIsStartedVideo(false);
      setIsInVBMode(false);
      setIsBlur(false);
    } else {
      if (videoRef.current) {
        await localVideo?.start(videoRef.current);
        setIsStartedVideo(true);
      }
    }
  }, [isStartedVideo, setIsStartedVideo]);
  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await localAudio?.unmute();
        micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
        setIsMuted(false);
      } else {
        await localAudio?.mute();
        if (micFeedBackInteval) {
          clearInterval(micFeedBackInteval);
        }
        setIsMuted(true);
      }
    } else {
      await localAudio?.start();
      setIsStartedAudio(true);
    }
  }, [isStartedAudio, isMuted, setIsMuted, setIsStartedAudio]);
  const onMicrophoneMenuClick = async (key: string) => {
    const [type, deviceId] = key.split("|");
    if (type === "microphone") {
      if (deviceId !== activeMicrophone) {
        await localAudio.stop();
        setIsMuted(true);
        localAudio = ZoomVideo.createLocalAudioTrack(deviceId);
        await localAudio.start();
        setActiveMicrophone(deviceId);
      }
    } else if (type === "leave audio") {
      await localAudio.stop();
      setIsStartedAudio(false);
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (localVideo) {
      if (activeCamera !== key) {
        await localVideo.switchCamera(key);
      }
    }
  };
  useMount(() => {
    mountDevices().then(devices => {
      setMicList(devices.mics);
      setCameraList(devices.cameras);
      setSpeakerList(devices.speakers);
      if (devices.speakers.length > 0) {
        setActiveSpeaker(devices.speakers[0].deviceId);
      }
      if (devices.mics.length > 0) {
        setActiveMicrophone(devices.mics[0].deviceId);
      }
    });
  });
  const onTestSpeakerClick = () => {
    if (microphoneTesterRef.current) {
      microphoneTesterRef.current.destroy();
      microphoneTesterRef.current = undefined;
    }
    if (isPlayingAudio) {
      speakerTesterRef.current?.stop();
      setIsPlayingAudio(false);
      setOutputLevel(0);
    } else {
      speakerTesterRef.current = localAudio.testSpeaker({
        speakerId: activeSpeaker,
        onAnalyseFrequency: value => {
          setOutputLevel(Math.min(100, value));
        },
      });
      setIsPlayingAudio(true);
    }
  };
  const onTestMicrophoneClick = () => {
    if (speakerTesterRef.current) {
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = undefined;
    }
    if (!isPlayingRecording && !isRecordingVoice) {
      microphoneTesterRef.current = localAudio.testMicrophone({
        microphoneId: activeMicrophone,
        speakerId: activeSpeaker,
        recordAndPlay: true,
        onAnalyseFrequency: value => {
          setInputLevel(Math.min(100, value));
        },
        onStartRecording: () => {
          setIsRecordingVoice(true);
        },
        onStartPlayRecording: () => {
          setIsRecordingVoice(false);
          setIsPlayingRecording(true);
        },
        onStopPlayRecording: () => {
          setIsPlayingRecording(false);
        },
      });
    } else if (isRecordingVoice) {
      microphoneTesterRef.current?.stopRecording();
      setIsRecordingVoice(false);
    } else if (isPlayingRecording) {
      microphoneTesterRef.current?.stop();
      setIsPlayingRecording(false);
    }
  };
  let microphoneBtn = formatMessage(messages.testMic);
  if (isRecordingVoice) {
    microphoneBtn = formatMessage(messages.recording);
  } else if (isPlayingRecording) {
    microphoneBtn = formatMessage(messages.playing);
  }
  useUnmount(() => {
    if (isStartedAudio) {
      localAudio.stop();
    }
    if (isStartedVideo) {
      localVideo.stop();
    }
  });

  return (
    <Flex
      flexDir="row"
      flexWrap="wrap"
      w="100%"
      gap="20px"
      align="center"
      justify="center"
      bgColor="white"
      boxShadow="lg"
      borderRadius="2xl"
      p="10px">
      <VStack
        bgColor="gray.100"
        p="10px"
        borderRadius="lg"
        minW={{ lg: "500px", base: "100%" }}>
        <div className="preview-video">
          <video
            className={classNames({ "preview-video-show": !isInVBMode })}
            muted={true}
            ref={videoRef}
            playsInline
          />
          <canvas
            className={classNames({ "preview-video-show": isInVBMode })}
            width="1280"
            height="720"
            ref={canvasRef}
          />
        </div>
        {isIOSMobile() ? (
          <Popover
            isOpen={isOpen}
            onClose={onClose}
            placement="top"
            closeOnBlur={false}
            returnFocusOnClose={false}
            autoFocus={false}
            colorScheme="red">
            <PopoverTrigger>
              <HStack m="20px" borderRadius="2xl">
                <MicrophoneButton
                  isStartedAudio={isStartedAudio}
                  isMuted={isMuted}
                  onMicrophoneClick={onMicrophoneClick}
                  onMicrophoneMenuClick={onMicrophoneMenuClick}
                  microphoneList={micList}
                  speakerList={speakerList}
                  activeMicrophone={activeMicrophone}
                  activeSpeaker={activeSpeaker}
                />
                <CameraButton
                  isStartedVideo={isStartedVideo}
                  onCameraClick={onCameraClick}
                  onSwitchCamera={onSwitchCamera}
                  cameraList={cameraList}
                  activeCamera={activeCamera}
                  isBlur={isBlur}
                  isPreview={true}
                />
              </HStack>
            </PopoverTrigger>
            <PopoverContent color="white" bg="red.500" borderColor="red.500">
              <PopoverArrow bg="red.500" />
              <PopoverCloseButton />
              <PopoverBody>{formatMessage(messages.remember)}</PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <HStack p="20px" borderRadius="2xl">
            <MicrophoneButton
              isStartedAudio={isStartedAudio}
              isMuted={isMuted}
              onMicrophoneClick={onMicrophoneClick}
              onMicrophoneMenuClick={onMicrophoneMenuClick}
              microphoneList={micList}
              speakerList={speakerList}
              activeMicrophone={activeMicrophone}
              activeSpeaker={activeSpeaker}
            />
            <CameraButton
              isStartedVideo={isStartedVideo}
              onCameraClick={onCameraClick}
              onSwitchCamera={onSwitchCamera}
              cameraList={cameraList}
              activeCamera={activeCamera}
              isBlur={isBlur}
              isPreview={true}
            />
          </HStack>
        )}
      </VStack>
      {micList.length > 0 ? (
        <VStack align="center" justify="center">
          <VStack w="100%">
            <Text fontSize="lg">{formatMessage(messages.speaker)}</Text>
            <HStack w="100%">
              <Button
                minW="150px"
                colorScheme="red"
                onClick={onTestSpeakerClick}
                className="speaker-btn">
                {isPlayingAudio
                  ? formatMessage(messages.stop)
                  : formatMessage(messages.testSpeaker)}
              </Button>
              <Select
                maxW="200px"
                colorScheme="red"
                focusBorderColor="red.500"
                onChange={value => {
                  setActiveSpeaker(value.target.value);
                }}
                value={activeSpeaker}
                className="speaker-list">
                {speakerList.map(item => {
                  return (
                    <option value={item.deviceId} key={item.deviceId}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
            </HStack>
            <HStack w="100%">
              <Progress
                colorScheme="red"
                value={outputLevel}
                w="100%"
                borderRadius="lg"
              />
            </HStack>
          </VStack>
          <VStack w="100%">
            <Text fontSize="lg">{formatMessage(messages.microphone)}</Text>
            <HStack w="100%">
              <Button
                minW="150px"
                colorScheme="red"
                onClick={onTestMicrophoneClick}
                className="speaker-btn">
                {microphoneBtn}
              </Button>
              <Select
                maxW="200px"
                onChange={value => {
                  setActiveMicrophone(value.target.value);
                }}
                focusBorderColor="red.500"
                value={activeMicrophone}
                className="speaker-list">
                {micList.map(item => {
                  return (
                    <option value={item.deviceId} key={item.deviceId}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
            </HStack>
            <HStack w="100%">
              <Progress
                colorScheme="red"
                value={inputLevel}
                w="100%"
                borderRadius="lg"
              />
            </HStack>
          </VStack>
          <Spacer pt="20px" />
          <Button colorScheme="red" onClick={() => setIsPreview(false)}>
            {formatMessage(messages.enterCall)}
          </Button>
        </VStack>
      ) : (
        <VStack align="center" justify="center" px="100px">
          <Spinner color="red.500" size="xl" />
        </VStack>
      )}
    </Flex>
  );
};

export default PreviewContainer;
