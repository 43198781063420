/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import {
  AudioOutlined,
  AudioMutedOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { IconFont } from "../../../component/icon-font";
import { MediaDevice } from "../video-types";
import CallOutModal from "./call-out-modal";
import { MenuItem as MenuItemType, getAntdItem } from "./video-footer-utils";
import { useCurrentAudioLevel } from "../hooks/useCurrentAudioLevel";
import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  VStack,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";
import { messages } from "../../../lang/messages";
interface MicrophoneButtonProps {
  isStartedAudio: boolean;
  isMuted: boolean;
  isSupportPhone?: boolean;
  isMicrophoneForbidden?: boolean;
  disabled?: boolean;
  audio?: string;
  phoneCountryList?: any[];
  onMicrophoneClick: () => void;
  onMicrophoneMenuClick: (key: string) => void;
  onPhoneCallClick?: (
    code: string,
    phoneNumber: string,
    name: string,
    option: any,
  ) => void;
  onPhoneCallCancel?: (
    code: string,
    phoneNumber: string,
    option: any,
  ) => Promise<any>;
  className?: string;
  microphoneList?: MediaDevice[];
  speakerList?: MediaDevice[];
  activeMicrophone?: string;
  activeSpeaker?: string;
  phoneCallStatus?: { text: string; type: string };
}
const MicrophoneButton = (props: MicrophoneButtonProps) => {
  const { formatMessage } = useIntl();
  const {
    isStartedAudio,
    isMuted,
    audio,
    className,
    microphoneList,
    speakerList,
    phoneCountryList,
    activeMicrophone,
    activeSpeaker,
    phoneCallStatus,
    disabled,
    isMicrophoneForbidden,
    onMicrophoneClick,
    onMicrophoneMenuClick,
    onPhoneCallClick,
    onPhoneCallCancel,
  } = props;
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const level = useCurrentAudioLevel();
  const menuItems = [];
  if (microphoneList?.length && audio !== "phone") {
    menuItems.push(
      getAntdItem(
        formatMessage(messages.selectMic),
        "microphone",
        undefined,
        microphoneList.map(i =>
          getAntdItem(
            i.label,
            `microphone|${i.deviceId}`,
            activeMicrophone === i.deviceId && <CheckOutlined />,
          ),
        ),
        "group",
      ),
    );
  }
  if (speakerList?.length && audio !== "phone") {
    menuItems.push(
      getAntdItem(
        formatMessage(messages.selectSpeaker),
        "speaker",
        undefined,
        speakerList.map(i =>
          getAntdItem(
            i.label,
            `speaker|${i.deviceId}`,
            activeSpeaker === i.deviceId && <CheckOutlined />,
          ),
        ),
        "group",
      ),
    );
  }

  const onMenuItemClick = (payload: { key: any }) => {
    onMicrophoneMenuClick(payload.key);
  };
  const audioIcon = useMemo(() => {
    let iconType = "";
    if (isStartedAudio) {
      if (isMuted) {
        if (audio === "phone") {
          iconType = "icon-phone-off";
        } else {
          return <AudioMutedOutlined />;
        }
      } else {
        if (audio === "phone") {
          iconType = "icon-phone";
        } else {
          if (level !== 0) {
            iconType = "icon-audio-animation";
          } else {
            return <AudioOutlined />;
          }
        }
      }
    } else {
      if (isMicrophoneForbidden) {
        iconType = "icon-audio-warning";
      } else {
        iconType = "icon-headset";
      }
    }
    if (iconType) {
      return <IconFont type={iconType} />;
    }
  }, [level, audio, isMuted, isMicrophoneForbidden, isStartedAudio]);
  useEffect(() => {
    if (isStartedAudio) {
      setIsPhoneModalOpen(false);
    }
  }, [isStartedAudio]);
  return (
    <div className={classNames("microphone-footer", className)}>
      {isStartedAudio ? (
        <HStack spacing="0px" gap="0px">
          <IconButton
            aria-label=""
            icon={audioIcon}
            onClick={onMicrophoneClick}
            isDisabled={disabled}
            borderRightRadius="0px"
            colorScheme={isMuted ? "red" : "green"}
          />
          <Menu placement="auto">
            <MenuButton
              as={Button}
              isDisabled={disabled}
              borderLeftRadius="0px"
              colorScheme={isMuted ? "red" : "green"}>
              <ChevronUpIcon />
            </MenuButton>
            <MenuList>
              {menuItems.map((item: MenuItemType, index) => {
                return (
                  <VStack key={index}>
                    {(
                      item as {
                        label: React.ReactNode;
                        key: React.Key;
                        icon?: React.ReactNode;
                        children?: MenuItemType[];
                        type?: string;
                      }
                    )?.children ? (
                      <MenuOptionGroup
                        type="radio"
                        defaultValue={
                          item?.key === "microphone"
                            ? "microphone|default"
                            : "speaker|default"
                        }
                        title={(
                          item as {
                            label: React.ReactNode;
                            key: React.Key;
                            icon?: React.ReactNode;
                            children?: MenuItemType[];
                            type?: string;
                          }
                        )?.label?.toString()}>
                        {(
                          item as {
                            label: React.ReactNode;
                            key: React.Key;
                            icon?: React.ReactNode;
                            children?: MenuItemType[];
                            type?: string;
                          }
                        )?.children?.map((child, index) => {
                          return (
                            <MenuItemOption
                              key={index}
                              value={child?.key?.toString()}
                              onClick={() =>
                                onMenuItemClick(
                                  child as {
                                    label: React.ReactNode;
                                    key: React.Key;
                                    icon?: React.ReactNode;
                                    children?: MenuItemType[];
                                    type?: string;
                                  },
                                )
                              }>
                              {
                                (
                                  child as {
                                    label: React.ReactNode;
                                    key: React.Key;
                                    icon?: React.ReactNode;
                                    children?: MenuItemType[];
                                    type?: string;
                                  }
                                )?.label
                              }
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    ) : (
                      <MenuItem
                        key={index}
                        value={item?.key?.toString()}
                        onClick={() =>
                          onMenuItemClick(
                            item as {
                              label: React.ReactNode;
                              key: React.Key;
                              icon?: React.ReactNode;
                              children?: MenuItemType[];
                              type?: string;
                            },
                          )
                        }>
                        {
                          (
                            item as {
                              label: React.ReactNode;
                              key: React.Key;
                              icon?: React.ReactNode;
                              children?: MenuItemType[];
                              type?: string;
                            }
                          )?.label
                        }
                      </MenuItem>
                    )}
                  </VStack>
                );
              })}
            </MenuList>
          </Menu>
        </HStack>
      ) : (
        <Button colorScheme="red" onClick={onMicrophoneClick}>
          {audioIcon}
        </Button>
      )}
      <CallOutModal
        visible={isPhoneModalOpen}
        setVisible={(visible: boolean) => setIsPhoneModalOpen(visible)}
        phoneCallStatus={phoneCallStatus}
        phoneCountryList={phoneCountryList}
        onPhoneCallCancel={onPhoneCallCancel}
        onPhoneCallClick={onPhoneCallClick}
      />
    </div>
  );
};

export default MicrophoneButton;
